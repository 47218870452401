<template>

  <v-app-bar id="app-header-bar" color="#FFFFFF" height="95" light fixed>
    <v-app-bar-title>
      <a @click="redirectTo('MainPage')">
        <img :src="logo" height="80" class="float-left"/>
      </a>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <div class="d-none d-sm-flex">
      <v-btn text color="#312783" @click="redirectTo('MainPage')">Home</v-btn>
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="#312783" dark v-bind="attrs" v-on="on">
            Oferta
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="redirectToProduct('healthCare')">
            <v-list-item-title>Opieka medyczna</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="redirectToProduct('groupInsurance')">
            <v-list-item-title>Ubezpieczenia grupowe</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text color="#312783" @click="redirectTo('AboutMe')">O mnie</v-btn>
      <v-btn text color="#312783" @click="navigateToContact">Kontakt</v-btn>
    </div>
    <div class="d-flex d-sm-none">
      <v-menu offset-y transition="slide-y-transition" min-width="150px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="#312783" v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="redirectTo('MainPage')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="redirectToProduct('healthCare')">
            <v-list-item-title>Opieka medyczna</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="redirectToProduct('groupInsurance')">
            <v-list-item-title>Ubezpieczenia grupowe</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="redirectTo('AboutMe')">
            <v-list-item-title>O mnie</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="navigateToContact">Kontakt</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>

</template>

<script>


export default {
  name: "AppHeaderBar",
  data() {
    return {
      logo: require('/public/piasecki-partnerzy-logo.png'),
    }
  },
  methods: {
    redirectTo(name) {
      if (this.$route.name === name) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push({name: name})
      }
    },
    redirectToProduct(product) {
      if (this.$route.name === 'ProductPage' && this.$route.params.product === product) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push({name: 'ProductPage', params: {product: product}})
      }
    },
    navigateToContact() {
      if (this.$route.name === 'MainPage') {
        document.getElementById('contact_div').scrollIntoView({behavior: 'smooth'});
      } else {
        this.$router.push({name: 'MainPage', params: {goToContact: 'yes'}})
      }
    }
  }
}
</script>

<style scoped>
.title-link {
  text-decoration: none;
  color: black;
}
</style>