<template>
  <div id="main-page">
    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>
    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>
    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="15" tile min-height="350" class="pa-10">
          <div class="d-none d-sm-flex">
            <v-row align="center" class="pa-15">
              <v-col cols="12" md="12" lg="6" class="pa-10 align-self-center">
                <app-about-me-section/>
              </v-col>
              <v-col cols="12" md="12" lg="6">
                <app-products-list-section/>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex d-sm-none">
            <v-row align="center">
              <v-col cols="12" md="12" lg="6" class="pa-10 align-self-center">
                <app-about-me-section/>
              </v-col>
              <v-col cols="12" md="12" lg="6">
                <app-products-list-section/>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="15" tile min-height="350" class="pa-10">
          <v-container>
            <v-row>
              <v-col cols="12" id="contact_div">
                <app-contact/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      goToContact: false
    }
  },
  created() {
    if (this.$route.params.goToContact && this.$route.params.goToContact === 'yes') {
      setTimeout(function () {
        window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
      }, 300);
    }
  }
}
</script>

<style scoped>

</style>