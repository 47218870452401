import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from "../views/MainPage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'MainPage',
        component: MainPage
    },
    {
        path: '/o-mnie',
        name: 'AboutMe',
        component: () => import(/* webpackChunkName: "about-me" */ '../views/AboutMe')
    },
    {
        path: '/oferta/:product',
        name: 'ProductPage',
        component: () => import(/* webpackChunkName: "prodyct-page" */ '../views/ProductsPage')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0, behavior: 'smooth',}
    }
})

export default router
