<template>

  <div id="contact">

    <v-row align="stretch">
      <v-col cols="12" md="6" align="center">
        <v-img :src="'contact.jpg'" contain width="570">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#312783"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-center align-center">
        <v-list-item>
          <v-list-item-content align="center">
            <v-list-item-title class="text-h2 text-wrap">Marcin Piasecki</v-list-item-title>
            <v-list-item-title class="text-h5">
              <v-icon color="#312783" size="35">mdi-email</v-icon>&nbsp;&nbsp;marcin@piasecki.co
            </v-list-item-title>
            <v-list-item-title class="text-h5">
              <v-icon color="#312783" size="35">mdi-phone</v-icon>&nbsp;&nbsp;+48 662 190 700
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

  </div>

</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>