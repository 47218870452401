import Vue from "vue";

import AppHeaderBar from "./layouts/AppHeaderBar";
import AboutMeSection from "./main_page/AboutMeSection";
import ProductsListSection from "./main_page/ProductsListSection";
import AppFooter from "./layouts/AppFooter";
import HealthCare from "./main_page/HealthCare";
import Contact from "./main_page/Contact";
import AppContactDrawer from "./layouts/AppContactDrawer";
import GroupInsurance from "./main_page/GroupInsurance";

Vue.component('app-contact-drawer', AppContactDrawer);


Vue.component('app-header-bar', AppHeaderBar);
Vue.component('app-about-me-section', AboutMeSection);
Vue.component('app-products-list-section', ProductsListSection);
Vue.component('app-footer', AppFooter);
Vue.component('app-health-care', HealthCare);
Vue.component('app-contact', Contact);
Vue.component('app-group-insurance', GroupInsurance);
