<template>

  <div id="app-footer">
    <v-footer dark color="white" class="text-center">
      <v-container>
        <v-row class="w-100">
          <v-col>
            <small class="black--text">&copy; Copyright Marcin Piasecki 2021</small>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
<!--            <small class="grey&#45;&#45;text text&#45;&#45;darken-3">Karol Osica</small>-->
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>

</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      footer_links: [
        {
          text: 'Polityka prywatności',
          disabled: false,
          href: '/',
        },
        {
          text: 'Regulamin',
          disabled: false,
          href: '/',
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>