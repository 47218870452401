<template>

  <div id="products-list-section">
    <v-row align="center">
      <v-col v-for="(product, n) in products" :key="n" cols="12" md="6" align="center">
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 4 : 0" width="320"
                  @click="$router.push({name: 'ProductPage', params: {product: product.product_name}})">
            <v-img :src="product.image" contain max-width="720">
              <v-expand-transition>
                <div v-if="hover"
                     class="text-center transition-fast-in-fast-out v-card--reveal text-h6 white--text pa-3"
                     style="height: 25%; background-color: #312783">
                  {{ product.name }}
                </div>
              </v-expand-transition>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="#312783"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name: "ProductsListSection",
  data() {
    return {
      products: [
        {name: 'Opieka medyczna', image: `opieka_medyczna.jpg`, product_name: 'healthCare'},
        {name: 'Ubezpieczenia grupowe', image: `group_insurance.jpg`, product_name: 'groupInsurance'},
      ]
    }
  }
}
</script>

<style scoped>
.v-card {
  opacity: 1;
  transition: opacity .4s ease-in-out;
}

.v-card.on-hover {
  opacity: 0.3;
}
</style>