<template>

  <div>
    <v-row align="center">
      <v-col cols="12" lg="6" class="pa-10" align="center">
        <v-img contain :src="image" class="align-center grey">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" lg="6" class="pa-10 align-self-center text-justify">
        <p class="text-h5 text-center">Ubezpieczenia grupowe</p>
        Rynek ubezpieczeń grupowych jest jednym z najdynamiczniej rozwijających się
        obszarów w ubezpieczeniach , ciągle pojawiają się na nim nowe świadczenia, nowe
        warunki. Biorąc pod uwagę ich popularność, jest to jeden z najczęściej występujących
        benefitów pracowniczych. Funkcjonuje w kilku formach, sponsorowany przez
        pracodawcę zawiera świadczenia istotnie zabezpieczające obowiązki pracodawcy i
        pracownika. Charakteryzuje się dużą elastycznością w doborze zakresu ryzyk objętych
        ochroną, ich wariantów i poziomu sum ubezpieczenia.
        Programy są dobrowolne, często zabezpieczają nie tylko pracownika, lecz także jego
        bliskich. Chronią zdrowie, życie, a także wspierają go w radosnych chwilach typu
        urodzenie dziecka. Łączą różne korzyści.
        <br><br>
        Mając za sobą wiele lat funkcjonowania na rynku ubezpieczeń, współpracując z
        czołowymi Towarzystwami, możemy wspierać Klientów jako niezależni doradcy
        ubezpieczeniowi na każdym etapie – dokonując przeglądu rynku świadczeń, analizując
        koszty czy warunki, a także zarządzając programem ubezpieczeniowym i go wdrażając.
        W ramach naszej oferty pokazujemy, jak konstruować program, by spełnił oczekiwania
        Klienta.
        <br><br>
        Zamierzasz wprowadzić program ubezpieczeń grupowych w swojej Firmie, chcesz
        dokonać analizy warunków – skontaktuj się z nami.
        Marcin Piasecki i Partnerzy
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name: "GroupInsurance",
  data() {
    return {
      image: require('../../assets/img/group_insurance.jpg'),
    }
  },
}
</script>

<style scoped>

</style>