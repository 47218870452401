<template>

  <div>

    <v-row align="center">
      <v-col cols="12" lg="6" class="pa-10" align="center">
        <v-img contain :src="image" class="align-center grey">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" lg="6" class="pa-10 align-self-center text-justify">
        <p class="text-h5 text-center">Opieka medyczna</p>
        Opieka medyczna to obecnie jeden z najbardziej pożądanych przez pracownika
        benefitów. Panująca pandemia pokazała, że popularne powiedzenie, że zdrowie jest
        najważniejsze, nie jest tylko pustym hasłem, ale stało się wręcz elementem, który
        wpływa na postrzeganie atrakcyjności pracodawcy czy jego pozycji na rynku. Jest tym,
        czym pracodawca może się wyróżnić spośród konkurentów, dając pracownikowi
        poczucie bezpieczeństwa. Program warto więc konstruować tak, by zawierał najczęściej
        oczekiwane świadczenia, które będą lepiej dopasowane do potrzeb pracownika.
        <br><br>
        Mamy świadomość tego, jak trudno jest przebrnąć przez ogromną liczbę ofert płynących
        z rynku, ocenić ich funkcjonalność, przeanalizować, które elementy są najważniejsze, co
        będzie atrakcyjne i efektywne dla pracownika i pracodawcy pod względem kosztów i
        sposobu działania.
        Dlatego przeprowadzamy analizę rynku, przygotowujemy porównanie ofert. Wspieramy
        w podjęciu właściwego wyboru i pomagamy Klientowi w bieżącej obsłudze świadczeń.
        Jako niezależni doradcy dokonujemy analizy dostawców medycznych zarówno
        abonamentowych, jak i towarzystw ubezpieczeniowych.
        Chcesz dokonać analizy i wyboru – skontaktuj się z nami.
        Marcin Piasecki i Partnerzy
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name: "HealthCare",
  data() {
    return {
      image: require('../../assets/img/opieka_medyczna.jpg'),
    }
  },
}
</script>

<style scoped>

</style>