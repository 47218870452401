<template>
  <v-app class="main-background"
         style="background-repeat: no-repeat; background-attachment: fixed; background-size:cover;"
         :style="application_background">
    <app-header-bar/>
    <v-main>
      <router-view/>
    </v-main>
    <app-footer/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      show_contact: false
    }
  },
  computed: {
    application_background: function () {
      return {
        backgroundImage: `url(${require('../public/tło_piasecki.png')})`,
      };
    }
  },
}

</script>

<style>

.main-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.main-2-background {
  background-color: #312783;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

</style>
