<template>

  <div id="about-me-section">
    <v-row class="fill-height align-self-center">
      <v-col lign-self="start" class="pa-0" cols="12">
        <v-avatar class="profile" color="grey" size="164">
          <v-img :src="about_me_image">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </v-col>
      <v-col cols="12" class="pa-0 mt-3 text-justify">
        {{ about_me_short_text }}
        <router-link :to="{name: 'AboutMe'}" class="link-deco">W roku 2009 nawiązałem...</router-link>
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name: "AboutMeSection",
  data() {
    return {
      about_me_short_text: "Dzień dobry, nazywam się Marcin Piasecki. Moje doświadczenia zawodowe\n" +
          "sięgają roku 2003, kiedy rozpocząłem pracę w branży finansowej,\n" +
          "zdobywając umiejętności związane z działalnością ubezpieczeniową w\n" +
          "firmie Amplico AIG LIFE. Tam nabyłem umiejętności z zakresu\n" +
          "profesjonalnej działalności ubezpieczeniowej. W późniejszym okresie\n" +
          "kontynuowałem je w firmie Allianz, gdzie uczestniczyłem w wielu\n" +
          "projektach mających na celu rozwój produktów i współpracę z klientami, za\n" +
          "co wielokrotnie otrzymywałem nagrody w oddziale, w którym\n" +
          "rozpoczynałem pracę.",
      about_me_image: require('../../assets/img/23122a.jpg'),
    }
  },
}
</script>

<style scoped>

.link-deco {
  text-decoration: none;
}

</style>